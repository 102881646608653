/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: polyman (https://sketchfab.com/Polyman_3D)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/apple-iphone-15-pro-max-black-df17520841214c1792fb8a44c6783ee7
Title: Apple iPhone 15 Pro Max Black
*/

import * as THREE from 'three';
import React, { useEffect, useRef } from "react";
import { useGLTF, useTexture, } from "@react-three/drei";

function Led(props) {

  const { scene, nodes, materials } = useGLTF('/models/modulolow.gltf');

  const texture = useTexture(props.item.img);
  const textura = useTexture("/models/Textura Modulo.jpg")


  return (
 
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Naranjos007.geometry}
        material={materials.Material003}
        scale={-0.25}
      >
        <meshStandardMaterial roughness={10} map={textura}  emissiveMap={texture}/>
      </mesh>

 
  );
}

export default Led;

useGLTF.preload("/models/modulolow.gltf");
